// Gereral Imports
import "core-js/stable";
import "regenerator-runtime/runtime";
import "./app.scss";

// Imported Libraries
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Functions
import Navigation from "./js/Navigation";
import Headroom from "headroom.js";
import Slider from "./js/Slider";
import whoWeHelp from "./js/whoWeHelp";
import lazySizes from "lazysizes";
// Plugins
gsap.registerPlugin(ScrollTrigger);

function action(attr, func) {
  if (attr.indexOf(".") !== -1 || attr.indexOf("#") !== -1) {
    document.querySelectorAll(attr).forEach((el) => func(el));
  } else {
    document
      .querySelectorAll(`[data-action="${attr}"]`)
      .forEach((el) => func(el));
  }
}

function runActions() {
  // Imports JS from pages
  action("Slider", Slider);
  action("WhoWeHelp", whoWeHelp);

  // Button triggers nav popup
  Navigation(document.querySelectorAll(".nav-btn"));

  // Headroom (offset = distance to scroll for CSS to trigger)
  const headroomElement = document.querySelector("#main-header");
  const headroom = new Headroom(headroomElement, {
    offset: 50,
  });
  headroom.init();

  const languageButtons = document.querySelectorAll(".lang-btn");
  languageButtons.forEach((languageButton) => {
    languageButton.addEventListener("click", function (event) {
      const dropdownLanguages = languageButton.nextElementSibling;
      dropdownLanguages.classList.toggle("open");
    });
  });
}

runActions();

// Only used in dev
if (module.hot) {
  module.hot.accept();
}
