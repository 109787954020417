class WhoWeHelp {
  selected;
  cards = [];
  backgroundColors = ["#bcc99b", "#db9388", "#83a1ae", "#f1c781"];

  constructor(containerElement, selectedCard, selectedDetails) {
    this.selectedCard = selectedCard;
    this.selectedDetails = selectedDetails;
    this.previousButton = containerElement.querySelector(".whoWeHelpPrevious");
    this.nextButton = containerElement.querySelector(".whoWeHelpNext");
    this.counter = containerElement.querySelector(".whoWeHelpCounter");

    const cardElements = containerElement.querySelectorAll(".whoWeHelpCard");

    for (let i = 0; i < cardElements.length; i++) {
      const card = cardElements[i];
      card.id = parseInt(i);
      card.details = card.dataset.details;

      this.cards.push(card);
    }

    this.updateSelectedCard(this.cards[0]);
    this.addEventListeners();
  }

  addEventListeners() {
    this.cards.forEach((card) => {
      card.addEventListener("click", () => {
        this.updateSelectedCard(card);
      });
    });

    this.previousButton.addEventListener("click", this.previousCard.bind(this));
    this.nextButton.addEventListener("click", this.nextCard.bind(this));
  }

  nextCard() {
    if (parseInt(this.selected.id) >= this.cards.length - 1) {
      this.updateSelectedCard(this.cards[0]);
    } else {
      this.updateSelectedCard(this.cards[parseInt(this.selected.id) + 1]);
    }
  }

  previousCard() {
    if (parseInt(this.selected.id) === 0) {
      this.updateSelectedCard(this.cards[this.cards.length - 1]);
    } else {
      this.updateSelectedCard(this.cards[parseInt(this.selected.id) - 1]);
    }
  }

  updateSelectedCard(newCard) {
    this.selected = newCard;

    // Clone node instead of copying innerHTML
    const clonedCard = newCard.cloneNode(true);
    this.selectedCard.innerHTML = ""; // Clear existing content
    this.selectedCard.appendChild(clonedCard); // Append cloned card

    this.selectedCard.style.backgroundColor = this.backgroundColors[newCard.id];

    this.counter.innerHTML = `${parseInt(newCard.id) + 1}/${this.cards.length}`;

    this.selectedDetails.innerHTML = newCard.dataset.details; // Set the details text

    const url = newCard.dataset.url; // Retrieve URL from data attribute

    if (url) {
      // Wrap the selected details in a link
      this.selectedDetails.innerHTML = `<a href="${url}" class="mt-4">${newCard.dataset.details}</a>`;
    }

    this.resetAllCards();
    newCard.classList.add("-translate-y-4");
  }

  resetAllCards() {
    this.cards.forEach((card) => {
      card.classList.remove("-translate-y-4");
    });
  }
}

const whoWeHelp = (el) => {
  const containerElement = el;
  const selectedCard = containerElement.querySelector(".whoWeHelpSelectedCard");
  const selectedDetails = containerElement.querySelector(".whoWeHelpDetails");

  new WhoWeHelp(containerElement, selectedCard, selectedDetails);
};

export default whoWeHelp;
