export default function Nav(els) {
  const nav = document.querySelector(".nav-popup");
  const navContent = document.querySelector(".nav-popup-content");
  els.forEach((el) =>
    el.addEventListener("click", () => {
      el.classList.toggle('rotate-180')
      document.body.classList.toggle("overflow-x-hidden");
      navContent.classList.toggle("translate-x-[150%]");
      nav.classList.toggle("opacity-0");
      nav.classList.toggle("hidden");
    })
  );
}
