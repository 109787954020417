import Flickity from "flickity-fade";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Function to set the active button
function setActiveBtn(btns, index) {
  btns.forEach((btn) => btn.classList.remove("active"));
  btns[index].classList.add("active");
}

// Function to handle individual sliders
function Slider(el) {
  el.classList.remove("hidden");
  const options = el.dataset?.options ? JSON.parse(el.dataset.options) : {};
  const indexEl = el.parentElement.querySelector("[data-index]");
  var currentSlide = document.getElementById("current-slide");

  // Initialize Flickity slider with options, including 'contain: true'
  const flk = new Flickity(el, {
    ...options,
    contain: true, // Make sure slides are contained within the slider
  });

  // Function to move to the next slide
  function nextSlide() {
    flk.next();
  }
  function prevSlide() {
    flk.previous();
  }

  document
    .querySelectorAll(options?.nextButton)
    .forEach((el) => el.addEventListener("click", nextSlide));

  document
    .querySelectorAll(options?.prevButton)
    .forEach((el) => el.addEventListener("click", prevSlide));

  // Initialize Flickity sliders
  var flickitySliders = document.querySelectorAll(".scroll-slider");
  flickitySliders.forEach(function (el) {
    const options = el.dataset?.options ? JSON.parse(el.dataset.options) : {};
    var flickityInstance = new Flickity(el, options);

    // Enable scrolling through slides using the mouse wheel
    el.addEventListener("wheel", function (event) {
      event.preventDefault();
      if (event.deltaY > 0) {
        flickityInstance.next();
      } else {
        flickityInstance.previous();
      }
    });

    // Use ScrollTrigger to change slides on scroll
    gsap.to(el, {
      scrollTrigger: {
        trigger: ".center-block",
        start: "top 50%",
        end: "bottom 30%",
        scrub: true,
      },
    });

    // Handle custom navigation buttons
    const btns = document.querySelectorAll(".slider-button"); // Adjust this selector
    flickityInstance.on("change", (index) => {
      setActiveBtn(btns, index);
    });

    // Set the active class to the initial slide's button
    setActiveBtn(btns, 0);

    // Handle customNav and customNav2
    if (options.customNav) {
      const customNavBtns = document.querySelectorAll(
        options.customNav + " .slider-button"
      );
      flickityInstance.on("change", (index) => {
        setActiveBtn(customNavBtns, index);
      });
      setActiveBtn(customNavBtns, 0);
    }

    if (options.customNav2) {
      const customNav2Btns = document.querySelectorAll(
        options.customNav2 + " .slider-button"
      );
      flickityInstance.on("change", (index) => {
        setActiveBtn(customNav2Btns, index);
      });
      setActiveBtn(customNav2Btns, 0);
    }
  });

  // Hide the Flickity dots (add CSS to your stylesheet)
  const style = document.createElement("style");
  style.textContent = ".flickity-page-dots { display: none; }";
  document.head.appendChild(style);

  // Return a function to destroy the slider
  return () => {
    flk.destroy();
  };
}

export default Slider;
